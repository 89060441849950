import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Content from "../components/content"
import SEO from "../components/seo"
import NewsPreview from '../components/news-preview'
import Header from "../components/header"
import ImageBar from "../components/ImageBar"
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import CustomForm from '../components/CustomForm'
import Footer from "../components/footer"
import Img from "gatsby-image"

const HomePage = ({data}) => {
    const meta = data.graphAPI.homepage.meta
    const content = data.graphAPI.homepage.content
    return (
        <main>
            <div className="main">
              <Header />
              <ImageBar isHome="true" />
              <div className="container">
                <div className="main-content">
                    <SEO title={meta.title} />
                    <div className="news-title is-flex-tablet is-justify-content-space-between is-align-content-center is-flex-wrap">
                        <h2 className="title is-size-1">Latest News </h2>

                        <div className="enews" style={{position: 'relative', top: '1rem'}}>
                        <h4 style={{position: 'relative', top: '0.5rem', fontWeight: '600', marginBottom: '1rem', textTransform: 'uppercase', textShadow: '0px 0px 10px black'}} className="is-size-5 has-text-white is-inline-block enews-title">Signup for eNews</h4>
                        <MailchimpSubscribe
                        url="https://touchstonedistrictservices.us19.list-manage.com/subscribe/post?u=9e9660a8cc948aa82bf3acc40&amp;id=6017f8a613"
                        render={({ subscribe, status, message }) => (
                            <CustomForm
                            status={status}
                            message={message}
                            onValidated={formData => subscribe(formData)}
                            />
                        )}
                        />
                        </div>
                    </div>
                    <NewsPreview posts={data.graphAPI.blogPosts} />
                    <div className="has-text-centered" style={{margin: '-3rem 1rem 3rem'}}>
                        <p><strong>You've reached the end. <Link to="/archive">View News Archive</Link></strong></p>
                    </div>
                    <div className="title-section">
                        <h1 className="title is-size-1">{meta.title}</h1>
                    </div>
                    <div className="columns">
                    <div className="column is-8">
                    <Content content={content} isHome={false} />
                    </div>
                    <div className="column is-3 is-offset-1">
                        {/* <Link className="button is-dark mb-4 is-block" to="/election-information">Board of Directors Elections</Link>
                        <Link className="button is-dark mb-4 is-block" to="/es/election-information">Elecciones de la Junta Directiva</Link>
                        <Link className="button is-dark mb-4 is-block" to="/vi/election-information">Các Cuộc Bầu Cử Ban Giám Đốc</Link>
                        <Link className="button is-dark mb-4 is-block" to="/zh/election-information">董事會選舉</Link> */}
                        <Link className="button is-dark is-block" style={{textWrap: 'wrap', height: 'auto'}} to="/campaign-finance-reports">Candidate/Officeholder Campaign<br />Finance Reports</Link>
                        {/* <div className="message is-danger">
                            <div className="message-header">
                                DROUGHT CONTINGENCY
                            </div>
                            <div className="message-body">
                                STAGE 1 - VOLUNTARY<br />
                                <a className="button is-danger" href="/posts/2022-08-17/hcmud-284-stage-1-drought-contingency-in-effect">MORE INFO</a>
                            </div>
                        </div> */}
                    </div>
                    </div>
                </div>
              </div>
              <Footer />
            </div>
        </main>
    )
}

export default HomePage

export const homePageQuery = graphql`
    query homePage {
        useIt: file(relativePath: {eq: "water-use-it-wisely.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        twdb: file(relativePath: {eq: "TWDB_logo.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        wateriq: file(relativePath: {eq: "wateriq_logo.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        watersmart: file(relativePath: {eq: "logo_watersmart_20161218.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        epa: file(relativePath: {eq: "epa-logo.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        graphAPI {
            homepage {
                meta {
                    id
                    title
                    description
                }
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }
                }
            }
            blogPosts(limit: 6, sort: "publishedAt:desc") {
                id
                title
                description
                publishedAt
                externalLink
                expiresAt
                heroImage {
                    id
                    url
                    updated_at
                    documentFile {
                        childImageSharp {
                            fluid(maxWidth: 2048){
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
        } 
    }
`


